import {
  Input,
  LoaderPopup,
  RenderIf,
  TopActionBar,
  useToasts,
  Text,
  Button,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { $ManageKeys, $WhiteContainer } from '../StoreManagement.styled'
import {
  getLocation,
  useDebouncedEffect,
  useRouter,
  useSelectStore,
} from 'hooks'
import React, { useState } from 'react'
import { Div, Grid, FlexBox } from 'styledComponent'
import { useTheme } from 'styled-components'
import {
  DIFF_OBJ,
  ERROR_DECODE,
  FILTER_EMPTY,
  GET_SEL_VALUE,
} from 'utils/reusables'
import { _selectOptionsGenerator } from 'utils/formatter'
import { useEffect, useCallback } from 'react'
import {
  createStore,
  getBrandOptions,
  getCompanyOptions,
  getStoreDetails,
  updateStore,
} from 'redux/actions/storeMgtActions'
import { useDispatch } from 'react-redux'
import {
  FORM_INITS,
  REQUIRED_FIELDS,
  CUSTOM_LINK_TYPES,
  URL_REGEXP,
} from '../constants'
import {
  checkCustomLinksValidation,
  customLinksArrayToObj,
  customLinksObjToArray,
  getPathStatus,
} from '../utils'
import { FormExtras } from '../components'
import { cloneDeep, omitBy } from 'lodash'
import { AddBlueIcon, DeleteRedIcon, ExternalLinkIcon } from 'assets/icons'
import { debounce } from 'lodash-es'

export const StoreForm = () => {
  const { history, params, pathname } = useRouter()
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const { toast } = useToasts()

  const {
    data: { companyOptions, brandOptions, storeDetails, screenLoading },
  } = useSelectStore('strMgt')
  const { data: vendorId } = useSelectStore('vendorIds.selectedVendor.value')

  const { isCreate, isView, isEdit, isRetail, act } = getPathStatus(pathname)
  const type = isRetail ? 'offline' : 'online'
  const homeRoute = isRetail
    ? '/storeManagement?tab=Retail%20Stores'
    : '/storeManagement?tab=E-Commerce%20Stores'
  const editRoute = isRetail
    ? `/storeManagement/editRetailStore/${params?.id}`
    : `/storeManagement/editEcomStore/${params?.id}`
  const pageLabel = isRetail ? 'Retail Stores' : 'E-Commerce Stores'

  const [form, setForm] = useState(FORM_INITS?.Store)
  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  useDebouncedEffect(
    () => {
      getLocation(form?.country, form?.state).then(
        ({ countryOptions, stateOptions, cityOptions }) => {
          setCountryOptions(countryOptions)
          setStateOptions(stateOptions)
          setCityOptions(cityOptions)
        }
      )
    },
    [form?.country, form?.state],
    200
  )

  const [showErrorLink, setShowErrorLink] = useState(false)

  const debounceShowErrorLink = useCallback(
    debounce(() => setShowErrorLink(true), 1000),
    []
  )

  useEffect(() => {
    dispatch(getCompanyOptions())
    dispatch(getBrandOptions())
    params?.id && dispatch(getStoreDetails(params?.id))
    return () => {
      setForm({})
    }
  }, [])

  useDebouncedEffect(
    () => {
      if (storeDetails?._id === params?.id) {
        setForm({
          ...FORM_INITS?.Store,
          ...storeDetails,
          ...(storeDetails?.customLinks
            ? { customLinks: customLinksObjToArray(storeDetails?.customLinks) }
            : {}),
        })
      }
    },
    [storeDetails],
    100
  )

  const handleChange = (field, value) => {
    setForm((frm) => ({ ...frm, [field]: value }))
  }

  const getCommonProps = (id, label, placeholder) => ({
    id,
    label,
    key: id,
    placeholder,
    disabled: isView,
    containerMargin: '0',
    inputWidth: '400px',
    variant: 'input',
    value: form?.[id] || '',
    onChange: (e) => handleChange(id, e?.target?.value),
  })

  const getSpecificProps = {
    storeCompanyId: {
      variant: 'single-select',
      options: companyOptions,
      value: GET_SEL_VALUE(form?.storeCompanyId, companyOptions) || '',
      onChange: (e) => handleChange('storeCompanyId', e?.value),
    },
    storeBrandId: {
      variant: 'single-select',
      options: brandOptions,
      value: GET_SEL_VALUE(form?.storeBrandId, brandOptions) || '',
      onChange: (e) => handleChange('storeBrandId', e?.value),
    },
    pos: {
      inputWidth: '185px',
      type: 'number',
    },
    country: {
      variant: 'single-select',
      options: countryOptions,
      value: GET_SEL_VALUE(form?.country, countryOptions),
      onChange: (e) => handleChange('country', e?.value),
    },
    state: {
      variant: 'single-select',
      options: stateOptions,
      value: GET_SEL_VALUE(form?.state, stateOptions),
      onChange: (e) => handleChange('state', e?.value),
    },
    city: {
      variant: 'single-select',
      options: cityOptions,
      value: GET_SEL_VALUE(form?.city, cityOptions),
      onChange: (e) => handleChange('city', e?.value),
    },
    address: {
      rows: '5',
      variant: 'textarea',
    },
  }

  const onClickToAddLinks = () => {
    const customLinks = cloneDeep(form.customLinks)
    if (customLinks.length === CUSTOM_LINK_TYPES.length) {
      return
    }

    customLinks.push({
      key: '',
      value: '',
    })

    setForm((state) => ({
      ...state,
      customLinks,
    }))
  }

  const onCustomLinkChange = (index, value, key) => {
    const customLinks = cloneDeep(form.customLinks)
    customLinks[index][key] = value

    if (key === 'value') {
      const isValidUrl = URL_REGEXP.test(value)
      if (!isValidUrl) {
        customLinks[index].isInvalid = true
      } else {
        delete customLinks[index].isInvalid
      }
    }
    debounceShowErrorLink()
    setForm((state) => ({
      ...state,
      customLinks,
    }))
  }

  const onCustomLinkDelete = (index) => {
    const customLinks = cloneDeep(form.customLinks)
    if (!customLinks[index]) return

    customLinks.splice(index, 1)

    setForm((state) => ({
      ...state,
      customLinks,
    }))
  }

  const onSave = async () => {
    try {
      const tempForm = { ...form }
      const unFilled = Object.keys(tempForm)?.find(
        (item) =>
          !tempForm[item] && REQUIRED_FIELDS?.[pageLabel]?.includes(item)
      )
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')
      if (unFilled) {
        throw `Please fill ${label}`
      } else if (
        tempForm.customLinks.length &&
        !checkCustomLinksValidation(tempForm.customLinks)
      ) {
        throw 'Custom link(s) needs to be non-empty valid links.'
      } else {
        if (tempForm.customLinks) {
          tempForm.customLinks = customLinksArrayToObj(tempForm.customLinks)
        }
        const res = await createStore(
          FILTER_EMPTY({
            ...tempForm,
            type,
            vendorId,
          })
        )
        if (res) {
          dispatch({
            type: 'SET_STORE_UPSERT_DATA',
            payload: res.data,
          })
          toast('Store created Successfully', 'success')
          history.push(homeRoute)
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  const onUpdate = async () => {
    try {
      let reqdForm = DIFF_OBJ(form, storeDetails)
      const unFilled = Object.keys(reqdForm)?.find(
        (item) => !form[item] && REQUIRED_FIELDS?.[pageLabel]?.includes(item)
      )
      // Omit keys with empty values
      reqdForm = omitBy(reqdForm, (value) => value === null || value === '')
      const label = document
        .getElementById('label' + unFilled)
        ?.getAttribute('label')

      if (unFilled) {
        throw `Please fill ${label}`
      } else if (Object.keys(reqdForm)?.length === 0) {
        throw `Please change attributes to update details`
      } else if (
        reqdForm?.customLinks.length &&
        !checkCustomLinksValidation(reqdForm?.customLinks)
      ) {
        throw 'Custom link(s) needs to be non-empty valid links.'
      } else {
        if (reqdForm?.customLinks) {
          reqdForm.customLinks = customLinksArrayToObj(reqdForm.customLinks)
        }

        const res = await updateStore({
          ...reqdForm,
          vendorId,
          type,
          _id: params.id,
        })
        if (res) {
          dispatch({
            type: 'SET_STORE_UPSERT_DATA',
            payload: res.data,
          })
          toast('Store updated Successfully', 'success')
          history.push(homeRoute)
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }

  return (
    <$WhiteContainer>
      <Navbar heading={`Store Management - ${pageLabel}`} />
      <LoaderPopup isPopOpen={screenLoading} />
      <TopActionBar
        title={`${act} Store Data`}
        onExit={() => history.push(homeRoute)}
        extras={
          <FormExtras
            isView={isView}
            isCreate={isCreate}
            isEdit={isEdit}
            onUpdate={onUpdate}
            onSave={onSave}
            onEdit={() => history.push(editRoute)}
          />
        }
      />
      <Grid
        columns="auto auto auto"
        columnGap={spacing.x3l}
        p={spacing.xl}
        pt={spacing.l}
      >
        <Div>
          <Input
            required
            {...getCommonProps(
              'storeCompanyId',
              'Select Company',
              'ABC PVT LTD'
            )}
            {...getSpecificProps?.storeCompanyId}
          />
          <Input
            required
            {...getCommonProps(
              'storeBrandId',
              'Select Brand',
              'Fantastic Foods'
            )}
            {...getSpecificProps?.storeBrandId}
          />
          {/* <Input
            required
            {...getCommonProps(
              'storeName',
              'Store Name',
              'Fantastic Resturaunts'
            )}
          /> */}
          <Input
            {...getCommonProps(
              'storeInCharge',
              'Store Incharge Name',
              'Mr. Rahul Roy'
            )}
          />
          <FlexBox justify="space-between">
            <Input
              {...getCommonProps(
                'storeContact',
                'Primary Contact Number',
                'Enter Contact Number'
              )}
              {...getSpecificProps?.pos}
              maxLength={10}
              required
            />
            <Input
              maxLength={10}
              {...getCommonProps(
                'storeContact2',
                'Secondary Contact Number',
                'Enter Contact Number'
              )}
              {...getSpecificProps?.pos}
            />
          </FlexBox>
          <FlexBox justify="space-between">
            <Input
              {...getCommonProps('noOfEmployee', 'No. of Employees', '10')}
              {...getSpecificProps?.pos}
            />
            <Input
              {...getCommonProps('storeSize', 'Store Size', '10')}
              {...getSpecificProps?.pos}
            />
          </FlexBox>
          <Input
            required
            {...getCommonProps(
              'displayAddress',
              'Display Address',
              'Enter Display Address'
            )}
          />
          <Input
            {...getCommonProps('gstNumber', 'GST No.', 'Enter GST Number')}
          />
          <FlexBox justify="space-between">
            <Input
              {...getCommonProps('cinNumber', 'CIN No.', 'Enter CIN Number')}
            />
          </FlexBox>
        </Div>
        <Div>
          <Input
            {...getCommonProps('country', 'Select Country', 'Select Country')}
            {...getSpecificProps?.country}
          />
          <Input
            {...getCommonProps('state', 'Select State', 'Select State')}
            {...getSpecificProps?.state}
          />
          <Input
            {...getCommonProps('city', 'Select City', 'Select City')}
            {...getSpecificProps?.city}
          />
          <FlexBox justify="space-between">
            <Input
              {...getCommonProps('pincode', 'Pin Code', 'Enter Pin Code')}
              {...getSpecificProps?.pos}
              maxLength={6}
            />
            <Input
              {...getCommonProps('storeCode', 'Store Code', 'Enter Store Code')}
              {...getSpecificProps?.pos}
              type="text"
            />
          </FlexBox>
          <Input
            {...getCommonProps('address', 'Address', 'Enter Address here')}
            {...getSpecificProps?.address}
          />
          <Input
            {...getCommonProps(
              'fssaiLicNo',
              'FSSAI LIC No.',
              'Enter FSSAI LIC Number'
            )}
          />
        </Div>
        {isRetail && (
          <Div>
            <Input
              required
              {...getCommonProps('noOfPos', 'No. of POS', '1')}
              {...getSpecificProps?.pos}
              maxLength={5}
            />
            {/* <Input
              required
              {...getCommonProps('posName', 'POS Name', 'Enter POS Name')}
              {...getSpecificProps?.pos}
              type="text"
            /> */}
            {!isCreate && (
              <Input label="Manage Keys">
                <$ManageKeys
                  onClick={() =>
                    history.push(`/storeManagement/manageKeys/${params?.id}`)
                  }
                >
                  Manage Keys
                </$ManageKeys>
              </Input>
            )}
          </Div>
        )}
      </Grid>

      <Div width="50%" padding={`0 ${spacing.xl} ${spacing.xl}`}>
        <Text variant="medium">Custom Link(s)</Text>
        <RenderIf active={form?.customLinks?.length}>
          {form?.customLinks?.map((customLink, index) => (
            <FlexBox justify="flex-start" key={index} gap="12px">
              <Input
                variant="single-select"
                label={`Custom Link Type ${index + 1}`}
                options={CUSTOM_LINK_TYPES.filter(
                  ({ value }) =>
                    form?.customLinks?.findIndex((ele) => ele.key === value) ===
                    -1
                )}
                inputWidth="300px"
                value={GET_SEL_VALUE(customLink?.key, CUSTOM_LINK_TYPES) || ''}
                onChange={(e) => onCustomLinkChange(index, e?.value, 'key')}
                disabled={isView}
                menuPlacement="auto"
              />
              <Input
                variant="input"
                value={customLink?.value || ''}
                onChange={(e) =>
                  onCustomLinkChange(index, e?.target?.value, 'value')
                }
                containerMargin="0"
                inputWidth="300px"
                label={`Custom Link ${index + 1}`}
                placeholder="https://example.com"
                validationError={customLink.isInvalid ? 'Invalid Link' : ''}
                labelAlign="baseline"
                disabled={isView}
                isLink={true}
                showErrorLink={showErrorLink}
              />
              <FlexBox
                justify="space-between"
                key={index}
                gap="8px"
                style={{ margin: '0px 0px -16px' }}
              >
                <Div
                  style={{
                    alignSelf: 'center',
                    padding: '8px 0',
                    width: '25px',
                  }}
                >
                  <DeleteRedIcon
                    style={{
                      ...(isView ? {} : { cursor: 'pointer' }),
                      height: '20px',
                      width: '100%',
                    }}
                    onClick={
                      isView ? () => {} : () => onCustomLinkDelete(index)
                    }
                  />
                </Div>
                <Div
                  style={{
                    alignSelf: 'center',
                    padding: '8px 0',
                    width: '25px',
                  }}
                >
                  <a
                    href={customLink?.value}
                    rel="noreferrer noopener nofollow"
                    target="_blank"
                  >
                    <ExternalLinkIcon
                      style={{
                        cursor: 'pointer',
                        height: '20px',
                        width: '100%',
                      }}
                    />
                  </a>
                </Div>
              </FlexBox>
            </FlexBox>
          ))}
        </RenderIf>
        <RenderIf
          active={form?.customLinks?.length !== CUSTOM_LINK_TYPES.length}
        >
          <Button
            variant="primaryOutline"
            onClick={onClickToAddLinks}
            isDisabled={isView}
          >
            <AddBlueIcon
              style={{ verticalAlign: 'text-top', marginRight: '4px' }}
            />
            <span>Add Custom Link</span>
          </Button>
        </RenderIf>
      </Div>
    </$WhiteContainer>
  )
}
