export const forKantiSweets = ['xjknhjkkfcs8m6', 'ld034402k9sfdpsy']

export const mcDonaldsVendor = 'danny.dsouza@mcdonaldsindia.com'

export const cinepolisVendorId = '41yfja9q8keoa17df'
export const nykaaVendorId = '5c7hzqknstqhza'
export const mirajVendorId = '3qp6rkl1kj2b86'
export const connplexVendorId = '2bajwkl92osfrk'
export const nyCinemasVendorId =
  process.env.REACT_APP_ENV !== 'PROD' ? 'ert0dikkpa46uk' : '4w0h5klky7ydhv'
export const bhutaniCineplex = 'dkf3zujllw5y4ek'
export const trentVendorId =
  process.env.REACT_APP_ENV !== 'PROD' ? '885jwqlptigz37' : '4w0h5kljtup1ig'
export const decathVendorIds = ['hr5pq1kfk5qccf7x', '4gb0i29jkl7lk5hb']
export const rajhansId =
  process.env.REACT_APP_ENV !== 'PROD' ? '40599vqkghlawf0' : '97jcjlna8k1t7'
export const movieMaxVendorId = '4corql28y0sda'

const commonVendors = [
  'contact@kantisweets.com',
  'rajesh.pawar@vipbags.com',
  'tejaswini.gc@decathlon.com',
  'shantanu@rarerabbit.in',
  'sudip.s@kompanero.in',
  // 'accounts@magsons.in',
  'rrgoldpalaceark@gmail.com',
  'venkatg@burgerking.in',
  'amitkumar.jha@nykaa.com',
  'jai@billme.co.in',
  'kuber@billme.co.in',
  'raghuveer@billme.co.in',
  'billmedemo@gmail.com',
  mcDonaldsVendor,
  'crm.vretail@gmail.com', // Centros
  'billmedemo@gmail.com',
]

export const analyticsEnabledVendors = {
  PA: [...commonVendors],
  CA: [...commonVendors, 'amit@shreelifestyle.com', 'pradhan@miniklub.in'],
  CRM: [...commonVendors, 'amit@shreelifestyle.com', 'pradhan@miniklub.in'],
  SETTINGS: [...commonVendors],
}

// India + Mexico
export const CinepolisCompanyIds = ['41yfja9q8keoa2xld', '41yfja9q8kek3rmwu']

export const centroCashier = [
  'bhhod@vretailgroup.com',
  'forum@vretailgroup.com',
  'gb.vretail@gmail.com',
  'gtrhod.vretail@gmail.com',
  'rjy.vretail@gmail.com',
  'kkn@vretailgroup.com',
  'dmjh@centrogrande.com',
]
