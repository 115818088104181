import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import { spacing, borderRadius, color } from 'design-system'
import { Text, Button } from 'components/Common'
import CustomPopup from '../CustomPopup'
import { Div, FlexBox } from 'styledComponent'

import SearchIcon from 'images/search.svg'

export const $CheckboxLabel = styled(Text)`
  line-height: 24px;
  margin-right: ${spacing.m};
`

export const $AddNew = styled.div`
  position: relative;
  top: ${({ top }) => top || '-38px'};
  left: ${({ left }) => left || '300px'};

  button {
    text-decoration: underline;
    margin-top: -4px;
  }
`

export const $PopContainer = styled.div`
  > div {
    padding: ${spacing.l};
  }
  > div:first-child,
  > div:last-child {
    padding: ${spacing.m} ${spacing.l};
    background-color: ${({ theme }) => theme.colors.aquaHaze};
  }
`

export const $Character = styled.div`
  padding: ${spacing.xs} ${spacing.l};
  border: solid 1px ${({ theme }) => theme.colors.darkMercury};
  margin: -${spacing.m} 0;
  background: #ededed;
  width: ${(props) => props.width};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

export const $UploadBox = styled.div`
  background-color: ${(props) =>
    props.whiteBackground && props.theme.colors.white};
  position: relative;
  margin: auto;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) =>
    props.hideBorder
      ? ''
      : props.primary
      ? `2px dashed ${props.theme.colors.primary.default}`
      : `2px dashed ${props.theme.colors.darkMercury}`};
  border-radius: ${(props) => props.theme.borderRadius.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: ${(props) => props.theme.borderRadius.default};
    small {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.primary.default};
      padding: ${spacing.xs};
    }
  }
  big {
    max-width: 100%;
    max-height: 100%;
  }
  video,
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
  }
  img {
    min-width: 100%;
    min-height: 100%;
  }
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
  }
`

export const $Position = styled(FlexBox)`
  position: absolute;
  left: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  z-index: 100;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary.default};
  align-items: center;
  justify-content: center;
`

export const $DeleteCarousel = styled($Position)`
  cursor: pointer;
  left: unset;
  right: -10px;
  background-color: ${({ theme }) => theme.colors.error};
`

export const $Container = styled.div`
  padding: ${(props) => (props.noPadding ? '0' : spacing.l)};
  background-color: ${({ theme }) => theme.colors.white};
`

export const $CampaignContainer = styled($Container)`
  padding: ${spacing.xl};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: 0px 2px 4px 4px hsla(0, 0%, 25%, 0.1);
  ${space};
`

export const $SegmentCharacteristics = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  padding: ${spacing.xl};
  flex-basis: 48%;

  & > table td {
    &:first-child {
      width: 200px;
      color: ${({ theme }) => theme.colors.medium};
    }
  }
`

export const $DateTitle = styled.span`
  display: block;
  width: 180px;
  padding: ${spacing.m};
  border: 1px solid #dbdbdb;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  cursor: pointer;
`

export const $SelectButton = styled(Button)`
  font-size: 12px;
  min-width: 100px;
`

export const $Label = styled(FlexBox)`
  border: 1px solid #dbdbdb;
  color: ${({ theme }) => theme.colors.dark};
  padding: ${spacing.m} ${spacing.l};
  gap: ${spacing.l};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  align-items: center;
`
export const $SelectedBrandOrStore = styled.div`
  min-width: 125px;
  padding: 8px 16px;
  border: 1px solid #dadada;
  border-radius: 6px;

  span {
    margin-right: 8px;
    color: #33475b;
  }
`

export const $SelectPopup = styled(CustomPopup)`
  &.modal_view {
    grid-template-rows: 40px auto 50px;
    height: auto;
  }
`

export const $PreviewPane = styled.div`
  width: 450px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  padding: ${spacing.m} ${spacing.xl};
  box-sizing: border-box;
  max-height: 300px;
  overflow: auto;
`

export const $MediaContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const $BlueBox = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  padding: ${(props) => (props.padding ? props.padding : spacing.l)};
  width: ${(props) => (props.width ? props.width : 'auto')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  height: ${(props) => (props.height ? props.height : 'fit-content')};
`

export const $ComponentTab = styled.div`
  width: 100%;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.colors.primary.default};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const $SBBPdfPreview = styled($BlueBox)`
  min-width: 420px;
  height: auto;
  max-height: 500px;
  overflow: auto;
`

export const $PublishStatus = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: ${(props) =>
    props.completed
      ? props.theme.colors.success.default
      : props.theme.colors.primary.default};
  background-size: 15px;
`

export const $PublishButton = styled(Button)`
  font-weight: bold;
  text-decoration: underline;
`

export const $SquareButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background-color: ${({ theme }) => theme.colors.white};
  :hover {
    border-color: ${({ theme }) => theme.colors.transparent};
    background-color: ${(props) => props.theme.colors.primary.default};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.transparent};
      }
    }
  }
  svg {
    path {
      stroke: ${(props) => props.theme.colors.pickledBluewood};
    }
  }
`

export const $AEHeader = styled(FlexBox)`
  margin: ${spacing.l};
  span:first-child {
    flex: 1;
  }
`

export const $Filter = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[300]};
`

export const $SelectedFilter = styled.div`
  min-width: 250px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.m} ${spacing.l};
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[300]};
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${props.theme.colors.primary[100]};
      color: ${props.theme.colors.neutral[900]};
    `};

  ${(props) =>
    props.isFilterActive &&
    css`
      color: ${props.theme.colors.primary.dark};
    `}
`

export const $ActiveFilter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-left: 1px solid ${(props) => props.theme.colors.neutral[300]};
`

export const $MarginlessLine = styled.hr`
  margin: ${spacing.s} 0;
  ${space}
`

export const $AETableContainer = styled.div`
  padding: 0 ${spacing.l};
  table {
    margin: 0 0 ${spacing.xl};
  }
  ${space};
`
export const $Ellipsis = styled.div`
  margin: ${(props) => (props.margin ? props.margin : 'auto')};
  text-align: center;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${space};
`
export const $MarkExternalUse = styled.div`
  position: relative;
  cursor: pointer;
  /* > span {
    display: none;
    color: ${({ theme }) => theme.colors.primary.default};
    border: 1px solid ${({ theme }) => theme.colors.primary.default};
    padding: ${spacing.m} ${spacing.l};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    position: absolute;
    bottom: -36px;
    left: 50%;
    width: fit-content;
    transform: translateX(-50%);
    filter: drop-shadow(-4px 0px 6px rgba(0, 0, 0, 0.161));

    &:hover {
      display: block;
    }
  }
  &:hover {
    > span {
      display: block;
    }
  } */
`
export const $WhiteContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
  ${space};
`

export const $UploadSidebar = styled.div`
  width: 200px;
  border-right: 1px solid ${({ theme }) => theme.colors.darkMercury};
`

export const $UploadSidebarItem = styled.div`
  width: 100%;
  cursor: pointer;
  padding: ${spacing.m} ${spacing.l};
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkMercury};
  color: ${({ theme }) => theme.colors.grey};
  font-size: 12px;
  font-weight: bold;
  :hover {
    background-color: ${({ theme }) => theme.colors.solitude};
    color: ${({ theme }) => theme.colors.primary.default};
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.solitude};
      color: ${({ theme }) => theme.colors.primary.default};
    `};
`
export const $File = styled.div`
  border: ${(props) =>
    props?.active
      ? `1px solid ${props.theme.colors.primary.default}`
      : `1px dashed ${props.theme.colors.darkMercury}`};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  width: 104px;
  height: 104px;
  img {
    width: 100px;
    height: 100px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    object-fit: contain;
  }
`
export const $Browse = styled(FlexBox)`
  align-items: center;
  justify-content: flex-end;
  margin-top: ${spacing.l};

  span {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 14px;
  }
  > div {
    position: relative;
    height: 30px;
    width: 100px;
    margin-left: ${spacing.l};
    cursor: pointer;
    input {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;
    }
  }
  small,
  button {
    color: ${({ theme }) => theme.colors.pickledBluewood};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border: 1px solid ${({ theme }) => theme.colors.darkMercury};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    font-weight: bold;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  ${space};
`

export const $SearchInput = styled.div`
  position: relative;
  cursor: pointer;
  input {
    border: 1px solid ${({ theme }) => theme.colors.pickledBluewood};
    width: ${(props) => props.size || '40'}px;
    height: ${(props) => props.size || '40'}px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${spacing.l};
    margin-right: ${spacing.ml};
    -webkit-transition: width 0.5s;
    -moz-transition: width 0.5s;
    transition: width 0.5s;
    &:focus,
    :hover,
    :not(:placeholder-shown) {
      width: 400px;
      outline: none;
      padding-right: ${spacing.xl};
    }
    ${(props) =>
      props.inputWidth &&
      css`
        width: ${props.inputWidth};
        &:focus,
        :hover,
        :not(:placeholder-shown) {
          width: ${props.inputWidth};
        }
      `}
  }
  span {
    position: absolute;
    top: 10px;
    right: 22px;
    background: ${({ theme }) => theme.colors.white} url(${SearchIcon}) center;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    pointer-events: none;
  }
`

export const $DateInput = styled.input`
  border: solid 1px ${({ theme }) => theme.colors.darkMercury};
  padding: ${spacing.sm} ${spacing.m};
  margin-right: ${spacing.l};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  ${space};
`

export const $StoreTags = styled.span`
  background-color: ${({ theme }) => theme.colors.alto};
  color: ${({ theme }) => theme.colors.dark};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${spacing.s} ${spacing.m};
  margin: ${spacing.s};
  font-size: 10px;
`

export const $YoutubeContainer = styled.div`
  iframe {
    max-width: 100%;
    max-height: 300px;
  }
`

export const $MoreOptions = styled.div`
  position: relative;
  width: 80px;
  margin: auto;
  > svg {
    width: 30px;
    height: 40px;
    padding: 0 ${spacing.sm};
    border-radius: 2px;
    cursor: pointer;
    transform: rotate(90deg);
  }
  div {
    position: absolute;
    width: 220px;
    left: -196px;
    top: -8px;
    padding: ${spacing.ml};
    background-color: ${(props) => props.theme.colors.white};
    display: none;
    flex-direction: column;
    text-align: left;
    border-radius: ${(props) => props.theme.borderRadius.default};
    box-shadow: rgba(0, 0, 0, 0.35) 2px 2px 8px;
    span {
      cursor: pointer;
      padding: ${spacing.sm};
      display: flex;
      align-items: center;
      font-size: 14px;
      color: ${(props) => props.theme.colors.grey};
      svg {
        width: 18px;
        height: 18px;
        margin-right: ${spacing.m};
      }
      :hover {
        background-color: ${(props) => props.theme.colors.solitude};
      }
    }
  }
  :hover {
    > svg {
      color: ${(props) => props.theme.colors.white};
      box-shadow: rgba(0, 0, 0, 0.35) 2px -2px 4px;
      background-color: ${(props) => props.theme.colors.white};
    }
    div {
      display: flex;
    }
  }
`

export const $SelectCoupon = styled(Div)`
  border-radius: ${(props) => props.theme.borderRadius.default};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.solitude};
  }
  input {
    width: 16px;
    height: 16px;
    margin-top: ${spacing.s};
  }
`

export const $Delete = styled.div`
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
`

export const $BillContainer = styled.div`
  background-color: hsla(0, 0%, 60%, 0.8);
  padding: 20px 12px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const $SmsMessage = styled.div`
  border: 1px solid ${(props) => props.theme.colors.darkMercury};
  border-radius: ${(props) => props.theme.borderRadius.default};
  padding: ${spacing.l};
  white-space: pre-wrap;
  word-wrap: break-word;
  span {
    color: ${(props) => props.theme.colors.grey};
    font-size: 14px;
  }
`

export const $SmsPreview = styled.div`
  padding: ${spacing.l};
  white-space: pre-wrap;
  word-wrap: break-word;
  position: relative;
  img {
    position: absolute;
    top: -30px;
    left: -30px;
    width: 300px;
    z-index: 1;
  }
  big {
    position: absolute;
    bottom: -15px;
    z-index: 5;
    left: 66px;
    width: 120px;
    text-align: center;
  }
  span {
    font-size: 12px;
    border: 1px solid ${(props) => props.theme.colors.darkMercury};
    border-radius: ${(props) => props.theme.borderRadius.default};
    font-size: 12px;
    position: absolute;
    z-index: 5;
    display: block;
    margin: 8px;
    bottom: -325px;
    left: 8px;
    padding: 8px;
    width: 205px;
    background-color: ${(props) => props.theme.colors.darkMercury};
  }
`

export const $SurveyPageImage = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #3b85fa;
  width: 100%;
  height: 136px;
  cursor: pointer;
  border: 1px dashed lightgray;
  border-radius: 4px;
  font-size: 24px;
  span {
    img {
      width: 100%;
      height: 136px;
      object-fit: contain;
    }
    svg {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  div {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`

export const $CouponDetails = styled.div`
  td {
    padding-right: 8px;
  }
`

export const $StatsTable = styled.table`
  border: 1px solid black;
  outline: 1px solid black;
  overflow: hidden;
  border-radius: 16px;
  width: 100%;
  margin-top: ${spacing.l};
  thead {
    tr:first-child {
      th {
        background-color: ${(props) => props.bgrd || color.success.default};
        color: #fff;
        text-align: center;
      }
    }
    tr:last-child {
      th {
        border: 1px solid black;
        text-align: center;
      }
    }
    tr {
      td {
        border: 1px solid black;
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      td {
        border-left: 1px solid black;
        text-align: center;
      }
    }
  }
`
