import React, { useState } from 'react'
import { Input, PopupContainer, useToasts } from 'components/Common'

export const CustomAttributeModal = ({
  isPopOpen,
  setIsPopOpen,
  onCustomChange,
}) => {
  const { toast } = useToasts()

  const [custom, setCustom] = useState('')

  const onSave = () => {
    if (!custom) {
      toast('Please enter your custom input.', 'error')
      throw ''
    }
    onCustomChange(custom, 'custom')
    setIsPopOpen(false)
    setCustom('')
  }

  const onCancel = () => {
    setIsPopOpen(false)
    setCustom('')
  }

  return (
    <PopupContainer
      isPopOpen={isPopOpen}
      setIsPopOpen={setIsPopOpen}
      heading="Custom Input"
      onCancel={onCancel}
      onSubmitClick={onSave}
    >
      <Input
        containerMargin="0"
        variant="input"
        label="Custom Input"
        placeholder="Customer"
        inputWidth="100%"
        value={custom}
        maxLength={30}
        onChange={(e) => setCustom(e.target.value)}
      />
    </PopupContainer>
  )
}
