import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { Div, FlexBox } from 'styledComponent'
import { spacing } from 'design-system'
import { useSelectStore } from 'hooks'
import { Input, Text, useToasts } from 'components/Common'
import { testCampaign } from 'redux/actions/autoEngageActions'
import { PublishItem } from '../../components/PublishItem'
import { $BlueBox, $PublishButton } from '../../AutoEngagement.styled'
import {
  ERROR_DECODE,
  GET_SEL_VALUE,
  CHECK_IF,
  WHOM_TYPES,
  WHEN_TYPES,
  GET_SEND_TIME,
} from '../../constants'
import { CampaignStats } from '../../components/CampaignPreview'
import { getEmbeddedDetails } from '../../email/EmailPreviewnPublish'
import TemplatePreview from '../Templates/TemplateCampaign/PreviewPanel'
import { getWhatsAppTemplates } from 'redux/actions/autoEngageActions'

const Preview = ({ form, setForm, hideSteps }) => {
  const [phone, setPhone] = useState('')
  const [segmentName, setSegmentName] = useState('')
  const dispatch = useDispatch()

  const { toast } = useToasts()

  const { data: whatsAppTemplates } = useSelectStore(
    'autoEngage.whatsAppTemplates.docs'
  )
  const {
    data: { segmentData, assetsCsvList },
  } = useSelectStore('autoEngage')

  useEffect(() => {
    const tempId = form.content?.body?.templateId
    if (tempId && tempId !== whatsAppTemplates[0]?._id) {
      dispatch(getWhatsAppTemplates({ _id: form.content?.body?.templateId }))
    }
  }, [form.content?.body?.templateId])

  useEffect(() => {
    if (form.targetGroup?.type) {
      if (form.targetGroup?.type === 'segments') {
        const segName =
          segmentData?.filter((seg) => seg._id === form.targetGroup?.id)[0]
            ?.name || '-'
        setSegmentName(segName)
      } else {
        const segName =
          assetsCsvList?.docs?.filter(
            (asset) => asset._id === form.targetGroup?.id
          )[0]?.name || '-'
        setSegmentName(segName)
      }
    }
  }, [form.targetGroup?.type, segmentData, assetsCsvList])

  const sendTest = () => {
    try {
      if (phone?.toString()?.length !== 10)
        throw { message: 'Please enter a valid phone number' }
      const res = testCampaign(form._id, { targetUser: phone })
      if (res) {
        setPhone('')
        toast('Message sent successfully', 'success')
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    }
  }
  return (
    <FlexBox wrap="flex-wrap" gap="2%">
      <Div width="58%">
        <Text variant="h3" type="main" weight="bold" mb={spacing.l}>
          {hideSteps ? 'Preview' : 'Preview & Publish'}
        </Text>
        {form?.status === 'draft' ? (
          <PublishItem header="Test Campaign">
            <FlexBox gap="10px" align="flex-end">
              <Input
                variant="input"
                type="number"
                containerMargin="0"
                label="Enter Test Phone Number"
                placeholder="9876543210"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                inputWidth="300px"
              />
              <$PublishButton
                noBorder
                mb="12px"
                fontSize="12px"
                variant="textualPrimary"
                onClick={sendTest}
              >
                Send Test Message
              </$PublishButton>
            </FlexBox>
            <Text variant="tiny" type="grey">
              Note: Your account will be charged for every test message sent, at
              the agreed WhatsApp notification rate.
            </Text>
          </PublishItem>
        ) : (
          <CampaignStats form={form} campaignType="WhatsApp" />
        )}
        <hr />
        <PublishItem
          width="100%"
          header="Campaign Details"
          completed={
            !Boolean(CHECK_IF(['name', 'vendorId', 'companyId'], form))
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Campaign Name :
            </Text>
            <Text variant="small" type="main">
              {form?.name || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Campaign Description :
            </Text>
            <Text variant="small" type="main">
              {form?.description || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px" padding="5px 0 0">
            <Text variant="small" type="grey">
              Created Date :
            </Text>
            <Text variant="small" type="main">
              {moment(form?.createdDate)?.format('DD MMM YYYY')}
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="Whom To Send"
          completed={
            !Boolean(CHECK_IF(['type', 'id'], { ...form?.targetGroup }))
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Selection Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form?.targetGroup?.type, WHOM_TYPES(true), 'label')
                ?.label || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              Segment Name :
            </Text>
            <Text variant="small" type="main">
              {segmentName}
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="When To Send"
          completed={
            Boolean(form?.validity) &&
            !(
              form?.validity !== 'immediate' &&
              Boolean(CHECK_IF(['startDate', 'scheduledTime'], form))
            )
          }
        >
          <FlexBox gap="10px" padding="10px 0 5px">
            <Text variant="small" type="grey">
              Selection Type :
            </Text>
            <Text variant="small" type="main">
              {GET_SEL_VALUE(form?.validity, WHEN_TYPES, 'label')?.label || '-'}
            </Text>
          </FlexBox>
          <FlexBox gap="10px">
            <Text variant="small" type="grey">
              When :
            </Text>
            <Text variant="small" type="main">
              <div
                dangerouslySetInnerHTML={{ __html: GET_SEND_TIME(form) || '-' }}
              />
            </Text>
          </FlexBox>
        </PublishItem>
        <hr />
        <PublishItem
          width="100%"
          header="Embedded Components"
          completed={Boolean(
            form?.content?.body?.variables ||
              form?.content?.body?.variablesButton
          )}
        >
          {getEmbeddedDetails(form?.content?.body?.variables)}
          {getEmbeddedDetails(form?.content?.body?.variablesButton)}
        </PublishItem>
      </Div>
      <Div width="40%">
        <TemplatePreview data={whatsAppTemplates?.[0]} />
      </Div>
    </FlexBox>
  )
}

export default Preview
