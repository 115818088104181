const DEV = {
  host: 'http://localhost:4040',
  paymentUrl: 'https://securegw-stage.paytm.in/theia/processTransaction',
  billUrl: 'http://localhost:3000',
  showAutoEngage: true,
  ssoConfig: {
    redirectUrl: 'https://dashboard.dev.razorpay.in/user/identifier/billme',
    cookieName: 'rzp_mid',
  },
  captchaKey: '6LfIy9spAAAAAPaai0cQZvJ9IU5cgUMFrgRhN6Qy',
}

const TEST = {
  host: 'https://billmepartner-development.stage.billmepartner.com',
  paymentUrl: 'https://securegw-stage.paytm.in/theia/processTransaction',
  billUrl: 'https://test.yourbill.me',
  showAutoEngage: true,
  ssoConfig: {
    redirectUrl: 'https://dashboard.dev.razorpay.in/user/identifier/billme',
    cookieName: 'rzp_mid',
  },
  captchaKey: '6LfIy9spAAAAAPaai0cQZvJ9IU5cgUMFrgRhN6Qy',
}

const PROD = {
  host: 'https://api.billmepartner.com',
  paymentUrl: 'https://securegw.paytm.in/theia/processTransaction',
  billUrl: 'https://yourbill.me',
  showAutoEngage: false,
  ssoConfig: {
    redirectUrl: 'https://dashboard.razorpay.com/user/identifier/billme',
    cookieName: 'rzp_mid',
  },
  captchaKey: '6LfIy9spAAAAAPaai0cQZvJ9IU5cgUMFrgRhN6Qy',
}

module.exports =
  process.env.REACT_APP_ENV === 'TEST'
    ? TEST
    : process.env.REACT_APP_ENV === 'PROD'
    ? PROD
    : DEV
