import { formatDateInFilters } from './getDateFormat'

export const sortViaBills = [
  {
    attribute: 'Store Name',
    active: false,
  },
  {
    attribute: 'Invoice Number',
    active: false,
  },
  {
    attribute: 'Contact no.',
    active: false,
  },
  {
    attribute: 'Amount',
    active: false,
  },
  {
    attribute: 'Transaction',
    active: false,
  },
  {
    attribute: 'Date/Time',
    active: false,
  },
]

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
]

export const twelve_hours = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
]

export const minutes = Array.from({ length: 59 }, (_, i) =>
  `0${i + 1}`.slice(-2)
)

export const timeState = ['AM', 'PM']

export const transactionType = [
  {
    value: 'BM',
    label: 'BillMe',
    color: '#1bd097',
  },
  {
    value: 'BMPrint',
    label: 'BM + Print',
    color: 'orange',
  },
  {
    value: 'Print',
    label: 'Print',
    color: 'red',
  },
  {
    value: 'C.W',
    label: 'Close Window',
    color: 'darkgrey',
  },
]

export const defaultFilters = {
  amountRange: {
    isActive: false,
    value: {
      from: 0,
      to: 0,
    },
  },
  transactionType: {
    isActive: false,
    value: {
      type: [],
    },
  },
  selectedStoreIds: {
    isActive: false,
    value: [],
  },
  emailList: {
    isActive: false,
    value: [],
  },
  date: {
    isActive: true,
    value: {
      dates: {
        startDate: formatDateInFilters(new Date()),
        startValue: new Date(),
        endDate: formatDateInFilters(new Date()),
        endValue: new Date(),
      },
      time: {
        startTime: {
          hrs: '00',
          state: 'AM',
        },
        endTime: {
          hrs: '11',
          state: 'PM',
        },
      },
    },
  },
  totalSales: {
    isActive: false,
    value: {
      from: 0,
      to: 0,
    },
  },
  avgSales: {
    isActive: false,
    value: {
      from: 0,
      to: 0,
    },
  },
  transactions: {
    isActive: false,
    value: {
      transType: 'BM',
      type: 'Count',
      from: 0,
      to: 0,
    },
  },
}
