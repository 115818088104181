import React, { useRef, useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { space } from 'styled-system'
import DateRangePicker from 'react-daterange-picker'
import { spacing, borderRadius } from 'design-system'

import { Div, FlexBox } from 'styledComponent'
import WhiteTickIcon from 'assets/icons/white-tick.svg'
import ClearIcon from 'assets/icons/close-grey.svg'
import SearchIcon from 'assets/icons/search.svg'
import { CreateTags, Text, Button, Tooltip } from '.'
import { SketchPicker } from 'react-color'
import { useOnClickOutside } from 'hooks'
import { MONTHS, getYearsTillNow } from 'utils/dateUTC'

export const COLOR_ARRAY = [
  '#D9E3F0',
  '#F47373',
  '#8ED1FC',
  '#7BDCB5',
  '#BA68C8',
  '#FCB900',
  '#DCE775',
  '#F78DA7',
  '#D9E3F0',
  '#F47373',
  '#8ED1FC',
]

const VARIANTS = {
  input: 'input',
  search: 'search',
  textarea: 'textarea',
  range: 'range',
  radio: 'radio',
  color: 'color',
  singleSelect: 'single-select',
  multiSelect: 'multi-select',
  singleSelectRange: 'single-select-range',
  dateInput: 'date-input',
  dateRange: 'date-range',
  createTags: 'create-tags',
  radioTextColumn: 'radio-text-column',
  dateMonthAndYear: 'date-month-year',
}

const customSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    margin: state.selectProps.margin,
  }),
  control: (provided, state) => ({
    ...provided,
    maxHeight: state.selectProps.maxInputHeight,
    overflow: state.selectProps.inputOverflow,
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: state.selectProps.maxMenuHeight,
  }),
}

const $Input = styled.input`
  &&& {
    width: ${({ width }) => width || '240px'};
    border: ${({ border }) => border || ''};
  }
`

const $Textarea = styled.textarea`
  &&& {
    width: ${({ width }) => width || 'auto'};
  }
`
const $Radio = styled.div`
  display: flex;
  align-items: center;
  margin: ${spacing.s} 0;
  cursor: pointer;
  input {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  label {
    cursor: pointer;
    font-size: 14px !important;
    margin: 0 ${spacing.m} 0 !important;
  }
`

const $ClearInput = styled.div`
  position: relative;
  input {
    padding-right: ${(props) =>
      props.showClear ? spacing.xl : spacing.l} !important;
    padding-left: ${(props) =>
      props.showSearch ? spacing.xl : spacing.l} !important;
  }
  div {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    background-size: 20px 20px;
    ${({ showSearch }) =>
      showSearch
        ? css`
            background-image: url(${SearchIcon});
            left: 8px;
          `
        : css`
            background-image: url(${ClearIcon});
            right: 10px;
            cursor: pointer;
          `}
    display: ${(props) => (props.show ? 'block' : 'none')};
  }
`

const $Container = styled.div`
  margin: ${(props) =>
    props.containerMargin ? props.containerMargin : `0px 0px ${spacing.l}`};
  padding: ${(props) => props.padding || `${spacing.m} 0px`};
  ${({ containerWidth }) =>
    containerWidth &&
    css`
      width: ${containerWidth};
    `}

  label {
    font-size: 12px;
    margin: 0px 0px ${spacing.m};
  }

  ${$Input}, textarea {
    font-size: 14px;
    padding: ${spacing.m} ${spacing.l};
    width: 100%;
    border-radius: ${borderRadius.default};
    border: solid 1px #e6e6e6;
    background-color: ${({ theme }) => theme.white};
  }
`

const $Checkbox = styled(FlexBox)`
  white-space: nowrap;
  height: ${(props) =>
    props.theme.checkBoxVariants[props['variant'] || 'default']};

  label {
    margin: 0px;
  }

  & input[type='checkbox'] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    cursor: ${(props) => props.disabled && 'not-allowed'};
  }

  & input[type='checkbox']:checked ~ label::before {
    color: #fff;
    border-color: #007bff;
    /* background-color: #007bff; */
  }

  & input[type='checkbox']:focus ~ label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  & input[type='checkbox']:focus:not(:checked) ~ label::before {
    border-color: #80bdff;
  }

  & input[type='checkbox']:not(:disabled):active ~ label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff;
  }

  & input[type='checkbox']:disabled ~ label {
    color: #6c757d;
  }

  & input[type='checkbox']:disabled ~ label::before {
    background-color: #e9ecef;
  }

  & label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    min-width: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    padding-left: ${spacing.xl};
    cursor: ${(props) => props.disabled && 'not-allowed'};
  }

  & label::before {
    position: absolute;
    top: 13%;
    left: 0rem;
    display: block;
    width: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    height: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #adb5bd solid 1px;
    border-radius: ${borderRadius.small};
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  & label::after {
    position: absolute;
    top: 3px;
    left: 0px;
    display: block;
    width: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    height: ${(props) =>
      props.theme.checkBoxVariants[props['variant'] || 'default']};
    content: '';
    background: no-repeat 50%/50% 50%;
  }

  & input[type='checkbox']:checked ~ label::after {
    background: url('${WhiteTickIcon}');
  }

  & input[type='checkbox']:indeterminate ~ label::before {
    border-color: #007bff;
  }

  & input[type='checkbox']:indeterminate ~ label::after {
    background: url('${WhiteTickIcon}');
  }

  & input[type='checkbox']:disabled:checked ~ label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }

  & input[type='checkbox']:disabled:indeterminate ~ label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }

  &:disabled {
    cursor: not-allowed;
  }
  cursor: ${(props) => props.disabled && 'not-allowed'};

  ${space};
`

const $RangeContainer = styled(FlexBox)`
  span {
    margin: 0px ${spacing.l};
  }
`

// id will be unique and value != id. Reason - value will be dynamic and not constant. Id needs to be constant
// Have kept both handler, change your code to match with onCheckboxChange and then delete the handleCheckboxClick handler
// change checked to isChecked in your code

export const Checkbox = ({
  id,
  children,
  value,
  onCheckboxChange,
  isChecked,
  ...rest
}) => {
  return (
    <$Checkbox align="center" {...rest}>
      <input
        type="checkbox"
        id={id}
        onChange={onCheckboxChange}
        checked={isChecked}
        value={value}
      />
      <label htmlFor={id}>{children}</label>
    </$Checkbox>
  )
}

export const Input = ({
  children,
  label,
  toolTip = '',
  subLabel,
  variant,
  id,
  type,
  placeholder,
  onChange,
  onEnter = () => {},
  value,
  clear = () => {},
  showClear,
  rows = 2,
  cols,
  inputWidth,
  margin,
  separator,
  wrap,
  options,
  disabled,
  onMenuOpen,
  autoFocus,
  min,
  max,
  containerMargin,
  containerWidth,
  padding,
  direction,
  gap,
  maxLength,
  maxMenuHeight,
  isClearable = false,
  minDate,
  subComponent,
  showSearch = false,
  required = false,
  isEmail = false,
  validationError = false,
  prefix = '',
  labelAlign,
  minYear,
  isLink,
  showErrorLink,
  customKeyDownKeys,
  ...rest
}) => {
  const ref = useRef()
  const [showPicker, setShowPicker] = useState(false)

  useOnClickOutside(ref, () => setShowPicker(false))

  const maxLengthCheck = (object) => {
    if (maxLength && object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      )
    }
  }

  const getRadio = (option) => (
    <$Radio key={option.value} onClick={() => onChange(option.value)}>
      <input
        readOnly
        type="radio"
        value={option.value}
        checked={option.value === value}
        disabled={disabled}
      />
      <label htmlFor={option.value}> {option.label} </label>
    </$Radio>
  )

  const getVariant = () => {
    switch (variant) {
      case VARIANTS.textarea:
        return (
          <$Textarea
            id={id}
            name={id}
            rows={rows}
            cols={cols}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            width={inputWidth}
            maxLength={maxLength}
            disabled={disabled}
            required={required}
          />
        )

      /* for more than one input, we send an array */
      case VARIANTS.range:
        return (
          <$RangeContainer align="center" wrap={wrap}>
            {prefix ? <span>{prefix}</span> : null}
            <$Input
              type={type}
              name={id[0]}
              id={id[0]}
              onChange={onChange && onChange[0]}
              value={value && value[0]}
              width={inputWidth}
              min={min && min[0]}
              max={max && max[0]}
              disabled={disabled}
            />
            <span>{separator || 'to'}</span>
            <$Input
              type={type}
              name={id[1]}
              id={id[1]}
              onChange={onChange && onChange[1]}
              value={value && value[1]}
              width={inputWidth}
              min={min && min[1]}
              max={max && max[1]}
              disabled={disabled}
            />
          </$RangeContainer>
        )

      case VARIANTS.input:
        return (
          <$ClearInput
            showClear={showClear}
            showSearch={showSearch}
            show={value?.length > 0 || !!showSearch || false}
          >
            <$Input
              disabled={disabled}
              required={required}
              type={type}
              name={id}
              id={id}
              border={
                isLink && showErrorLink && validationError
                  ? '1px solid tomato'
                  : ''
              }
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              width={inputWidth}
              autoFocus={autoFocus}
              min={min}
              max={max}
              maxLength={maxLength}
              onKeyDown={(e) => e.key === 'Enter' && onEnter(e)}
              onInput={maxLengthCheck}
              {...rest}
            />
            {(showClear || showSearch) && !disabled && <div onClick={clear} />}
          </$ClearInput>
        )

      case VARIANTS.singleSelect:
        return (
          <Select
            id={id}
            isDisabled={disabled}
            isClearable={isClearable}
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            width={inputWidth}
            margin={margin}
            styles={customSelectStyles}
            onMenuOpen={onMenuOpen}
            maxMenuHeight={maxMenuHeight}
            {...rest}
          />
        )

      case VARIANTS.dateMonthAndYear:
        return (
          <FlexBox gap="8px">
            <Select
              id={id}
              isDisabled={disabled}
              isClearable={isClearable}
              options={MONTHS}
              name={id && id[0]}
              onChange={onChange && onChange[0]}
              value={value && value[0]}
              placeholder={placeholder}
              width={inputWidth}
              margin={margin}
              styles={customSelectStyles}
              onMenuOpen={onMenuOpen}
              maxMenuHeight={maxMenuHeight}
              required
              isOptionDisabled={(option) =>
                Number(value[1]?.value) === moment().year() &&
                Number(option?.value) > moment().month()
              }
              {...rest}
            />
            <Select
              isDisabled={disabled}
              isClearable={isClearable}
              options={getYearsTillNow(minYear).map((year) => ({
                label: year,
                value: year,
              }))}
              name={id && id[1]}
              onChange={onChange && onChange[1]}
              value={value && value[1]}
              placeholder={placeholder}
              width={inputWidth}
              margin={margin}
              styles={customSelectStyles}
              onMenuOpen={onMenuOpen}
              maxMenuHeight={maxMenuHeight}
              required
              {...rest}
            />
          </FlexBox>
        )

      case VARIANTS.multiSelect:
        return (
          <Select
            id={id}
            closeMenuOnSelect={false}
            isClearable={isClearable}
            isDisabled={disabled}
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            width={inputWidth}
            margin={margin}
            styles={customSelectStyles}
            onMenuOpen={onMenuOpen}
            maxMenuHeight={maxMenuHeight}
            isMulti
            {...rest}
          />
        )

      case VARIANTS.singleSelectRange:
        return (
          <$RangeContainer align="center" wrap={wrap}>
            <Select
              options={options[0]}
              name={id && id[0]}
              onChange={onChange && onChange[0]}
              value={value && value[0]}
              width={inputWidth}
              margin={margin}
              styles={customSelectStyles}
              disabled={disabled}
            />
            <span>{separator || 'to'}</span>
            <Select
              options={options[1]}
              name={id && id[1]}
              onChange={onChange && onChange[1]}
              value={value && value[1]}
              margin={margin}
              width={inputWidth}
              styles={customSelectStyles}
              disabled={disabled}
            />
          </$RangeContainer>
        )

      case VARIANTS.dateInput:
        return (
          <DatePicker
            placeholderText={placeholder}
            selected={value ? moment(value || new Date()).toDate() : ''}
            onChange={onChange}
            disabled={disabled}
            dateFormat="dd/MM/yyyy"
            minDate={minDate}
            customInput={
              <$Input
                placeholder={placeholder}
                name={id}
                id={id}
                width={inputWidth}
              />
            }
          />
        )

      case VARIANTS.dateRange: {
        const handleDate = (date) =>
          onChange({
            startDate: moment(date.start).toISOString(),
            endDate: moment(date.end).toISOString(),
          })
        const dateValue = moment.range(
          moment(value?.startDate || new Date())
            .clone()
            .startOf('day'),
          moment(value?.endDate || new Date())
            .clone()
            .endOf('day')
        )

        return (
          <Div position="relative">
            <Button
              variant="primaryOutline"
              fontSize="14px"
              onClick={() => setShowPicker(true)}
              isDisabled={disabled}
            >
              <i className="fas fa-calendar-alt" /> &nbsp;
              {moment(value?.startDate).format('DD MMM YYYY') +
                ' - ' +
                moment(value?.endDate).format('DD MMM YYYY')}
            </Button>
            {showPicker && (
              <Div
                position="absolute"
                top="45px"
                left="-200px"
                backgroundColor="white"
                width="630px"
                zIndex={100}
                ref={ref}
              >
                <DateRangePicker
                  numberOfCalendars={2}
                  onSelect={handleDate}
                  value={dateValue}
                  {...rest}
                />
              </Div>
            )}
          </Div>
        )
      }

      case VARIANTS.radio:
        return (
          <FlexBox gap={gap} direction={direction}>
            {options?.map((option) => getRadio(option))}
          </FlexBox>
        )

      case VARIANTS.radioTextColumn:
        return (
          <FlexBox gap={gap} direction="column">
            {options?.map((option, i) => (
              <>
                {getRadio(option)}

                {subComponent[i] && (
                  <FlexBox align="center">{subComponent[i]}</FlexBox>
                )}
              </>
            ))}
          </FlexBox>
        )

      case VARIANTS.createTags:
        return (
          <CreateTags
            isEmail={isEmail}
            disabled={disabled}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            customKeyDownKeys={customKeyDownKeys}
          />
        )

      case VARIANTS.color: {
        return (
          <Div position="relative">
            <$Input
              disabled={disabled}
              readOnly
              type="text"
              name={id}
              id={id}
              placeholder={placeholder}
              value={value}
              width={inputWidth}
              autoFocus={autoFocus}
              maxLength={maxLength}
              borderColor={value}
              onClick={() => setShowPicker(true)}
            />
            {showPicker && (
              <Div position="absolute" bottom="45px" left="0px" ref={ref}>
                <SketchPicker
                  color={value}
                  presetColors={COLOR_ARRAY}
                  width={`calc(${inputWidth || '300px'} - 20px)`}
                  onChangeComplete={(col) => onChange(col.hex)}
                />
              </Div>
            )}
            <Div
              position="absolute"
              type="button"
              top="0px"
              right="0px"
              width="22px"
              height="22px"
              backgroundColor={value}
              m={spacing.m}
              borderRadius="2px"
              onClick={() => setShowPicker(true)}
            />
          </Div>
        )
      }

      default:
        return null
    }
  }

  return (
    <$Container
      containerMargin={containerMargin}
      containerWidth={containerWidth}
      padding={padding}
    >
      {label && (
        <>
          <label
            htmlFor={id}
            id={'label' + id}
            label={label}
            style={{
              color: isLink
                ? showErrorLink && validationError
                  ? 'tomato'
                  : 'inherit'
                : validationError
                ? 'tomato'
                : 'inherit',
            }}
          >
            <FlexBox align={labelAlign || 'auto'}>
              {label}{' '}
              {required ? <span style={{ color: 'tomato' }}>*</span> : ''}
              {toolTip && <Tooltip content={toolTip} />}
              {(!!subLabel || validationError) && (
                <Text
                  variant="small"
                  type="grey"
                  style={{
                    display: 'inline-block',
                    color: validationError ? 'tomato' : 'inherit',
                  }}
                  ml={spacing.m}
                >
                  {isLink
                    ? showErrorLink && validationError
                      ? validationError
                      : subLabel
                    : validationError
                    ? validationError
                    : subLabel}
                </Text>
              )}
            </FlexBox>
          </label>
          <br />
        </>
      )}
      {getVariant()}
      {children}
    </$Container>
  )
}
